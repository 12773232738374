<template>
	<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
		<slot v-if="widgetLoad">
			<div class="widget-min-box-content text-center p-2">
				<div class="widget-loading"></div>
			</div>
		</slot>
		<slot v-else>
	
			<div class="widget-min-box-list min-show">
				<div class="list-widget-select-container">
					<i class="uil uil-calendar-alt list-widget-select-icon Datepicker" aria-hidden="true" style="padding-top: 12px;"></i>
					<label class="list-widget-select">
						<select v-model="eventsView" @change="eventsFilter()">
							<option value="day">بازه زمانی : روز</option>
							<option value="week">بازه زمانی : هفته</option>
							<option value="month">بازه زمانی : ماه</option>
						</select>
					</label>
				</div>

				<div class="list-widget-search-container" v-if="events.length"> 
					<i class="fa fa-search list-widget-search-icon" aria-hidden="true"></i>
					<input class="list-widget-search" type="text" placeholder="جستجو در رویداد ..." @keyup="eventsFilter()" v-model="eventsFilterInput">
				</div>

				<div class="list-widget list-dash-items list-widget-structure">

					<template v-for="event in events">

						<div class="list-row hover bold" :key="event.id" v-if="event.label_id != -1 && !event.hide">
							<!-- v-if="!event.hide" -->
							<div class="list-col l-title l-title-widget-structure">
								<span><i class="fa fa-circle-o list-event-icon default-event" :style="'color: ' + event.color"></i> {{ event.title }}</span>
							</div>
							<div class="list-col l-item pointer" @click="goEventEdit(event.id)">
								<i class="uil-edit-alt"></i>
							</div>
							<div class="list-col l-item red-color pointer" @click="removeEvent(event.id)">
								<i class="uil uil-trash remove-row"></i>
							</div>
						</div>

					</template>
				</div>
				<div class="line-widget-title">
					مدیریت برچسب ها
				</div>
				<div class="list-widget calender-tags  list-widget-add-container" data-list-type="calendar-tags">
					<div class="list-row no-filter hover bold">
						<v-swatches
							v-model="label.color"
							:swatches="colors"
							row-length="6"
							shapes="circles"
							show-border
							popover-x="left"
							swatch-size="24"
							:trigger-style="{ width: '28px', height: '28px' }"
						></v-swatches>

						<div class="list-col l-title l-title-widget-structure ">
							<i class="uil uil-trash cancel-edit" v-if="labelEditId" @click="cancelEdit()"></i>
							<input :class="'list-widget-event-add ' + (labelEditId ? 'list-edit-mode' : '') " type="text" placeholder="افزودن برچسب جدید" v-model="label.title" @keyup.enter="addLabel()" ref="event-add-input">
						</div>
					</div>

					<div class="list-row no-filter hover bold">
						<div class="list-col l-title l-title-widget-structure">
							<span><i class="fa fa-circle list-event-icon" :style="'color: #3788d8;'"></i> برچسب عمومی</span>
						</div>
					</div>

					<div class="list-row no-filter hover bold">
						<div class="list-col l-title l-title-widget-structure">
							<span><i class="fa fa-circle list-event-icon" :style="'color: #000000;'"></i> رویداد های اقتصادی</span>
						</div>
					</div>

					<div v-for="label in labels" :key="label.id" class="list-row no-filter hover bold">
						<div class="list-col l-title l-title-widget-structure">
							<span><i class="fa fa-circle list-event-icon" :style="'color: ' + label.color + ';'"></i> {{ label.title }}</span>
						</div>
						<div class="list-col l-item pointer" @click="goEditLabel(label.id)">
							<i class="uil-edit-alt"></i>
						</div>
						<div class="list-col l-item red-color pointer" @click="removeLabel(label.id)">
							<i class="uil uil-trash remove-row"></i>
						</div>
					</div>

					<div class="line-widget-title">
						مدیریت رویداد های اقتصادی
					</div>

					<div class="list-row no-filter hover bold">
						<div class="list-col l-title l-title-widget-structure">
							<span>
								<input type="checkbox" v-model="allowEconomyEvents"  @change="loadData()">
								فعال سازی روی نمایه تقویم
							</span>
						</div>
					</div>
				</div>

			</div>
			
			<div class="widget-min-box-content">
				<div class="list-row l-head">
					<div class="list-col l-item">
						<span class="fa fa-arrow-right widget-min-back-to-list"></span>
					</div>
					<div class="list-col l-title">
						<span></span>
					</div>
					
					<div class="list-col l-item pointer hover head-color show-Event-modal" @click="open_add_event_modal()">
						<span class="btn btn-primary btn-sm list-col-left-btn">
							<div class="fa fa-plus l-item-btn-pages"></div>
							افزودن رویداد
						</span>
					</div>
					
					<!-- <div class="list-col l-item pointer hover head-color show-widget-settings-modal" @click="open_settings_modal()" v-if="editable && wrapper != 'dashboard'">
						<span>
							<div class="fa fa-cog l-item-btn-pages"></div>
							تنظیمات
						</span>
					</div> -->
				</div>

				<div class="tgju-widget-content full-padding mini-calendar">
					<FullCalendar ref="fullCalendar" :options="calendarOptions" id="calendar"></FullCalendar>
				</div>
			</div>
		</slot>
	</div>
</template>

<style lang="scss" scoped>
	.vue-swatches {
		border-left: 1px solid #e6e6e6;
		padding: 6px 7px;
		height: 40px;
	}

	.default-event {
		color: #3788d8;
	}

	.fc .fc-scrollgrid-section-liquid {
		height : 2px !important;
	}

	.cancel-edit {
		position: absolute;
		top: 14px;
		left: 15px;
		font-size: 14px;
	}

</style>

<script>
	import VSwatches from 'vue-swatches';
	import "vue-swatches/dist/vue-swatches.css"
	import moment from "moment-jalaali";
	import FullCalendar, { collectFromHash } from '@fullcalendar/vue'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
	import faLocale from "@fullcalendar/core/locales/fa";

	export default {
		name: 'CalendarView',
		props: ['widget', 'data', 'editable', 'wrapper'],
		components: {
			FullCalendar,
			VSwatches
		},
		data() {
			return {
				events: [],
				labels: [],
				label: {
					id: null,
					title: '',
					color: '',
				},
				allowEconomyEvents:true,
				eventsView: 'month',
				colors: ["#d50000", "#0b8043", "#f6bf26", "#8e24aa", "#f4511e", "#039be5", "#666", "#ff51bb", "#18ccd6", "#795548", ""],
				labelEditId: false,
				eventsFilterInput: '',
				
				calendarOptions: {
					headerToolbar: { 
						right: 'dayGridMonth,dayGridWeek,dayGridDay',
						center: 'title',
						left: 'prev,next today', 
					},
					plugins: [dayGridPlugin, interactionPlugin],
					initialView: 'dayGridWeek',
					locale: faLocale,
					selectable: true,
					selectHelper: true,
					lang: 'fa',
					isJalaali: true,
					isRTL: true,
					editable: true,
					droppable: true,
					draggable: true,
					eventResizableFromStart: true,
					eventDurationEditable: true,
					displayEventTime: false,
					eventLimit: true,
					eventClick: (calEvent, jsEvent, view) => {
						this.goEventEdit(calEvent.event.id);
					},
					events: []
				},

				responsive: {
					rightSection: true,
					leftSection: false,
				},
				widgetLoad: true,
			}
		},

		mounted() {
			this.$watch('data', function(newValue, oldValue) {
				if ( Object.keys(newValue).length && ( typeof oldValue == 'undefined' || !Object.keys(oldValue).length ) ) {
					this.loadData();
				}
			}, {immediate: true});
		},

		methods: {
			loadData() {
				let labels = [];
				this.data.labels.forEach(event => {
					labels.push({
						id: event.id,
						title: event.title,
						color: event.color,
					});
				});
				this.labels = labels;

				let events = [];

				this.data.events.forEach(event => {
					events.push({
						id: event.id,
						title: event.title,
						description: event.description,
						start: event.start,
						end: event.end,
						color: this.eventColor(event.label_id),
						label_id: event.label_id,
						editable: event.editable,
						hide: false,
					});
				});
				
				if (this.allowEconomyEvents) {
					this.data.economy_events.forEach(event => {
						events.push({
							id: event.id,
							title: event.title,
							description: event.description,
							start: event.start,
							end: event.end,
							color: this.eventColor(event.label_id),
							label_id: event.label_id,
							editable: event.editable,
							hide: false,
						});
					});
				}

				this.calendarOptions.events = events;
				this.events = events;

				this.widgetLoad = false;

				this.eventsFilter();

			},

			open_settings_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id;

				let modal_data = {
					modal: 'widget_settings_modal',
					callbackEvent,
					component: 'Settings/Calendar.vue',
					config: {
						data: {
							title: this.widget.title,
						}
					}
				};

				modal_data = this.$helpers.unbindObject(modal_data);

				this.$root.$emit('open_modal', modal_data);

				this.$helpers.setEvent(callbackEvent).then((status) => {
					if ( !status ) return;
					
					this.$root.$on(callbackEvent, (settings) => {
						this.widget.title = settings.title;

						let widget = this.$helpers.unbindObject(this.widget);

						this.$helpers.saveWidget(widget);
					});
				});
			},
			
			open_add_event_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_addEvent';

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'addCalendarEvent.vue',
					config: {
						title: 'افزودن رویداد',
						icon: 'uil uil-plus-circle with-icon',
						smallModal: true,
						data: {
							widget: this.widget,
							labels: this.labels,
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$helpers.setEvent(callbackEvent).then((status) => {
					if ( !status ) return;
					
					this.$root.$on(callbackEvent, (response) => {
						if ( response.action == 'addEvent' ) {
							let event = {
								id: response.data.event.id,
								title: response.data.event.title,
								description: response.data.event.description,
								start: moment(response.data.event.start, 'jYYYY/jMM/jDD').format('YYYY-MM-DD') + ' 00:00:00',
								end: moment(response.data.event.end, 'jYYYY/jMM/jDD').format('YYYY-MM-DD') + ' 23:59:59',
								color: this.eventColor(response.data.event.label_id),
								label_id: response.data.event.label_id,
								editable: true,
								hide: false,
							};
							
							this.calendarOptions.events.push(event);
							this.eventsFilter();

							Vue.prototype.$toast.success({
								title : 'موفق',
								message : 'رویداد با موفقیت ایجاد شد',
							});
						}
					});
				});
			},
			
			open_edit_event_modal(event) {
				let callbackEvent = this.$router.currentRoute.name + '_editEvent';

				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'addCalendarEvent.vue',
					config: {
						title: 'ویرایش رویداد',
						icon: 'uil uil-edit-alt with-icon',
						smallModal: true,
						data: {
							widget: this.widget,
							labels: this.labels,
							event
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$helpers.setEvent(callbackEvent).then((status) => {
					if ( !status ) return;

					this.$root.$on(callbackEvent, (response) => {
						if ( response.action === 'editEvent' ) {
							let index = this.$helpers.getIndex(this.events, response.data.event.id);

							let event = this.events[index];
							
							event.title = response.data.event.title;
							event.description = response.data.event.description;
							event.start = moment(response.data.event.start, 'jYYYY/jMM/jDD').format('YYYY-MM-DD') + ' 00:00:00';
							event.end = moment(response.data.event.end, 'jYYYY/jMM/jDD').format('YYYY-MM-DD') + ' 23:59:59';
							event.color = this.eventColor(response.data.event.label_id);
							event.label_id = response.data.event.label_id;

							this.eventsFilter();
						} else if ( response.action === 'removeEvent' ) {
							this.removeEvent(response.data.event.id);
						}
					});
				});
			},

			removeEvent(id) {
				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						let index = this.$helpers.getIndex(this.events, id);

						this.events.splice(index, 1);
						this.calendarOptions.events.splice(index, 1);
						
						this.$helpers.makeRequest('DELETE', '/calendar/event-delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});	
					}
				});
			},

			addLabel() {
				if ( !this.label.title ) return;

				if ( this.labelEditId ) {
					this.editLabel();
					return;
				}

				let api_data = {
					title: this.label.title,
					color: this.label.color,
				};

				this.$helpers.makeRequest('POST', '/calendar/store-label', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						this.label.id = api_response.data.response.id;
						this.labels.push(this.$helpers.unbindObject(this.label));
						this.label.id = null;
						this.label.title = '';
						this.label.color = '';
					}
				});
			},

			removeLabel(id) {
				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						let index = this.$helpers.getIndex(this.labels, id);

						this.labels.splice(index, 1);
						
						this.$helpers.makeRequest('DELETE', '/calendar/label-delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});	
					}
				});
			},

			goEditLabel(id) {
				let index = this.$helpers.getIndex(this.labels, id);
				let label = this.labels[index];

				this.labelEditId = label.id;
				this.label.title = label.title;
				this.label.color = label.color;

				this.$refs['event-add-input'][0].focus();
			},

			editLabel() {
				let index = this.$helpers.getIndex(this.labels, this.labelEditId);
				let label = this.labels[index];

				let api_data = {
					title: this.label.title,
					color: this.label.color,
				};

				this.$helpers.makeRequest('POST', '/calendar/edit-label/' + this.labelEditId, api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						this.labelEditId = false;
						label.title = this.label.title;
						label.color = this.label.color;

						this.label.title = '';
						this.label.color = '';
					}
				});
			},

			cancelEdit() {
				this.labelEditId = false;
				this.label.title = '';
				this.label.color = '';
			},

			eventColor(id) {
				let index = this.$helpers.getIndex(this.labels, id);
				let label = this.labels[index];
				
				let color = '';
				if ( typeof label != 'undefined' ) {
					color = label.color;
				}

				if (id == -1) {
					color = "#000";
				}

				return color;
			},

			goEventEdit(id) {
				let index = this.$helpers.getIndex(this.events, id);
				let event = this.$helpers.unbindObject(this.events[index]);
				event.start = moment(event.start).format('jYYYY/jMM/jDD');
				event.end = moment(event.end).format('jYYYY/jMM/jDD');
				this.open_edit_event_modal(event);
			},

			eventsFilter() {
				this.events.forEach(event => {
					//-----------
					let a = moment(new Date()).format('MM');
					let b = moment(this.$refs.fullCalendar.$options.calendar.currentData.currentDate).format('MM');
					//-----------
					if( a === b) {

						event.hide = true;

						if (this.eventsView == 'day') {
						
							let currentDate = moment(moment(new Date()).format('YYYY-MM-DD'));

							let startDate = moment(event.start).format('YYYY-MM-DD');
							
							let endDate = moment(event.end).format('YYYY-MM-DD');

							if(currentDate.isBetween(startDate, endDate, 'days', true)) {
								event.hide = false;
								return;
							}

						}
						
						if ( 
								event.title.indexOf(this.eventsFilterInput.trim()) > -1 
								&& ( moment(event.start).isSame(new Date(), this.eventsView)
								|| moment(event.end).isSame(new Date(), this.eventsView))
							) {
							event.hide = false;
						}

					} else {

						event.hide = true;

						let cu_du = this.$refs.fullCalendar.$options.calendar.currentData.currentDate;

						let startDate = moment(cu_du).startOf('months').format('YYYY-MM-DD');
						
						let endDate = moment(cu_du).endOf('months').format('YYYY-MM-DD');

						let eventstart = moment(event.start, "YYYY-MM-DD");

						if (event.title.indexOf(this.eventsFilterInput.trim()) > -1 && eventstart.isBetween(startDate, endDate, 'days', true)) {
							event.hide = false;
						}
					}

					
				});
			},
		}
	}

// import moment from "moment";
// import moment from "moment-jalaali";
// import fa from "moment/src/locale/fa";
// moment.locale("fa", fa);
// moment.loadPersian({dialect: 'persian-modern'});

// import 'path/to/moment.js'
// import 'path/to/moment-jalaali.js'

// import moment from "moment";
// import momentJalaali from "moment-jalaali";
// import fa from '@fullcalendar/core/locales/fa'
// import momentJalaali from '@/plugins/fullcalendar-Jalaali/lib/moment-jalaali.js'
// import '@/plugins/fullcalendar-Jalaali/locale/fa.js'

// import FullCalendar from '@/plugins/fullcalendar-Jalaali/fullcalendar'
</script>